import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popTip",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  data() {
    return {
      isShowAgain: false
    };
  },
  methods: {
    onUnderstand() {
      this.$parent.popTipShow = false;
    },
    onShowAgain() {
      this.isShowAgain = !this.isShowAgain;
    }
  }
};