import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "airdrop",
  data() {
    const taskList = [{
      label: this.$lang("Join channel"),
      target: "channel",
      icon: "tp"
    }, {
      label: this.$lang("Folow us on X"),
      target: "twitter",
      value: 1,
      icon: "twitter"
    }, {
      label: this.$lang("Connect wallet"),
      target: "wallet",
      icon: "ton"
    }];
    return {
      taskList,
      popBindWalletShow: false
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"])
  },
  methods: {
    ...userVuex.mapActions(["join"]),
    async onJoin(item) {
      if (item.target == "channel" || item.target == "telegram") {
        this.WebApp.openTelegramLink(this.setting.socials[item.target]);
      } else if (item.target == "wallet") {
        this.popBindWalletShow = true;
        return;
      } else {
        this.WebApp.openLink(this.setting.socials[item.target]);
      }
      if (!this.userData[`joined_${item.target}`]) {
        await this.join([item.target, null, 1]);
      }
    }
  }
};