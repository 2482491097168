var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.isMobile && 'wap',
    style: {
      fontFamily: _vm.themeMainColor(_vm.theme).fontFamily,
      background: _vm.themeMainColor(_vm.theme).main
    },
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    attrs: {
      "id": "ton-connect"
    }
  }), !_vm.isLoading ? _c('div', {
    staticClass: "loading"
  }, [_c('div', {
    staticClass: "loading-main"
  }, [_vm.theme === 'car' ? _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/loading.gif`),
      "alt": ""
    }
  }) : _vm._e(), _vm.theme === 'human' ? _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/loading.png`),
      "alt": ""
    }
  }) : _vm._e()])]) : _vm._e(), _vm.isLoading && _vm.isRouterAlive ? [_c('layout'), _vm.route === '/home' || _vm.route === '/tasks' || _vm.route === '/learderboard' || _vm.route === '/frends' || _vm.route === '/airdrop' ? [_c('m-nav')] : _vm._e()] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };