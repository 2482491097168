var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "upgold__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/ton-icon-big.png`),
      "alt": ""
    }
  })]), !_vm.tonConnect ? _c('div', {
    staticClass: "upgold__title"
  }, [_vm._v(_vm._s(_vm.$lang("Wallet Unconnect")))]) : _c('div', {
    staticClass: "upgold__title"
  }, [_vm._v(_vm._s(_vm.$lang("Wallet Connected")))]), _vm.userData.wallet || _vm.tonWallet.uiAddress ? _c('div', {
    staticClass: "upgold__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$lang('address')) + ":"), _c('b', [_vm._v(_vm._s(_vm._f("fmt_address")(_vm.userData.wallet ? _vm.userData.wallet : _vm.tonWallet.uiAddress, 10)))])]) : _vm._e(), _c('div', {
    staticClass: "btns"
  }, [!_vm.tonConnect ? [_c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.onTonConnect
    }
  }, [_vm._v(_vm._s(_vm.$lang("Connect And Bind")))])] : [_c('div', {
    staticClass: "btn btn-dis",
    on: {
      "click": _vm.onDisconnect
    }
  }, [_vm._v(_vm._s(_vm.$lang("Disconnect")))])]], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };