import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    firendTotal: 0,
    firendList: [],
    personalTotal: 0,
    personalList: [],
  },
  mutations: {
    setFirendTotal: (state, payload) => {
      state.firendTotal = payload;
    },
    setFirendList: (state, payload) => {
      state.firendList = payload;
    },
    setPersonalTotal: (state, payload) => {
      state.personalTotal = payload;
    },
    setPersonalList: (state, payload) => {
      state.personalList = payload;
    },
  },
  actions: {
    getFirendList: async ({ state, commit }) => {
      const rs = await Vue.prototype.$http.post("/ranking/friend",{});
      vailcode(rs, () => {
        commit("setFirendTotal", rs.data.total);
        commit("setFirendList", rs.data.list);
      });
    },
    getPersonalList: async ({ state, commit, dispatch }, level) => {
      const rs = await Vue.prototype.$http.post("/ranking/personal", { level });
      vailcode(rs, () => {
        commit("setPersonalTotal", rs.data.total);
        commit("setPersonalList", rs.data.list);
      });
    },
  },
  getters: {},
};
