import loadConfig from "@Web3WalletConfig/config.json";
export default {
  //格式化地址，len表示除了中间省略的部份以外的长度
  fmt_address: function (value, len) {
    if (!value) return;
    let sect_len = Math.floor(len / 2);
    let start = value.substr(0, sect_len);
    let end = value.substr(-sect_len + 1, sect_len);
    return start + "..." + end;
  },
  toThousands:function(num) {
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  },
  /**
     * 大于1k会自动转换为1k digits是小数点保留位数
     * @param {*} num 
     * @param {*} digits 
     * @returns 
     */
    nFormatter:function(num, digits) {
      const si = [
          { value: 1, symbol: "" },
          { value: 1E3, symbol: "K" },
          { value: 1E6, symbol: "M" },
          { value: 1E9, symbol: "G" },
          { value: 1E12, symbol: "T" },
          { value: 1E15, symbol: "P" },
          { value: 1E18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      let i;
      for (i = si.length - 1; i > 0; i--) {
          if (num >= si[i].value) {
              break;
          }
      }
      return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  },
  //拼接图片url
  setImageUrl(url, width, q = "50") {
    if (!url || url.includes(".svg")) return url;
    let img = new Image();
    img.src = url;
    let _url = `${url}?x-oss-process=image/resize,h_${width}/quality,q_50`;
    img = null;
    return _url;
  },

  getDistanceSpecifiedTime(dateTime) {
    // 指定日期和时间
    var EndTime = new Date(dateTime);
    // 当前系统时间
    var NowTime = new Date();
    var t = EndTime.getTime() - NowTime.getTime();
    var d = Math.ceil(t / 1000 / 60 / 60 / 24);
    return d;
  },

  //保留小数后位数,后面0忽略
  toPrecision(num, len = 4) {
    if (!num) return 0;
    let str = Number(num).toFixed(len);
    if (!/^[0-9.]+$/g.test(str)) return "0";
    while (str.includes(".") && (str.endsWith(".") || str.endsWith("0"))) {
      str = str.slice(0, -1);
    }
    return str;
  },
  txLink(hash, type='tx') {
    open(`${loadConfig.etherscan_url}${type}/${hash}`, "target");
  },
  toPre(num, leng = 3, isFormatted = true) {
    const thousandsSeparator = (n) => {
      const strSplit = n.toString().split(".");
      const integer = strSplit[0].split("");
      integer.reverse();
      const decimal = strSplit[1];
      const newInteger = [];
      for (let i = 0; i < integer.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          newInteger.push(",");
        }
        newInteger.push(integer[i]);
      }
      newInteger.reverse();
      let s = newInteger.join("");
      if (decimal) {
        s += `.${decimal}`;
      }
      return s;
    };
    const fomatFloat = (num, len) => {
      const reg = new RegExp(`^(.*\\..{${len}}).*$`);
      let _num = String(num).replace(reg, "$1");
      return Number(_num);
    };
    const toPrecision = (num, len) => {
      if (!num) return 0;

      let str = String(fomatFloat(num, len));
      if (!/^[0-9.]+$/g.test(str)) return "0";
      while (str.includes(".") && (str.endsWith(".") || str.endsWith("0"))) {
        str = str.slice(0, -1);
      }
      return str;
    };
    const tranNum = (num, len) => {
      let numArr = String(num).split(".");
      let decimal = [];
      let integer = thousandsSeparator(numArr[0]);
      if (numArr.length <= 1) {
        return integer;
      }
      const bool = numArr[1].split("").every((it, idx) => {
        if (it > 0) {
          return false;
        } else {
          decimal.push(it);
          return true;
        }
      });
      if (bool) {
        return integer;
      }
      if (decimal.length > len) {
        let x = toPrecision(`0.${numArr[1].slice(decimal.length)}`, len);
        let n = x.split(".");
        if (n.length > 1) {
          return `${integer}.{${decimal.length}}${n[1].slice(0, 1)}`;
        } else {
          return `${integer}`;
        }
      } else {
        let x = toPrecision(`0.${numArr[1]}`, len);
        let n = x.split(".");
        if (n.length > 1) {
          return `${integer}.${n[1]}`;
        } else {
          return `${integer}`;
        }
      }
    };
    const formatNumber = (num, precision = 2) => {
      const map = [
        { suffix: "T", threshold: 1e12 },
        { suffix: "B", threshold: 1e9 },
        { suffix: "M", threshold: 1e6 },
        // { suffix: "K", threshold: 1e3 },
        { suffix: "", threshold: 1 },
      ];

      const found = map.find((x) => Math.abs(num) >= x.threshold);
      if (!found || found.threshold === 1) {
        return tranNum(num, leng);
      } else {
        const formatted = fomatFloat(num / found.threshold, precision) + found.suffix;
        return formatted;
      }
    };

    if (!num) return 0;

    if (isFormatted) {
      return formatNumber(num);
    } else {
      return tranNum(num, leng);
    }
  },
  fomatFloat(num, len = 5) {
    const reg = new RegExp("/^(.*..{" + len + "}).*$/");
    let _num = String(num).replace(reg, "$1");
    return Number(_num);
  },

  /**
   * 格式化时间
   * @param {*} time 时间戳 毫秒
   * @param {*} type zh 中国格式 us 美国格式
   * @returns
   */
  formatTime(time = new Date().getTime(), type = "zh", isDate = true) {
    if (!isDate) {
      time = time * 1000;
    } else if (isNaN(time)) {
      time = new Date(time).getTime();
    }

    let currentTime = new Date(parseInt(time));
    let month = ("0" + (currentTime.getMonth() + 1)).slice(-2);
    let day = ("0" + currentTime.getDate()).slice(-2);
    let hour = ("0" + currentTime.getHours()).slice(-2);
    let minutes = ("0" + currentTime.getMinutes()).slice(-2);
    let seconds = ("0" + currentTime.getSeconds()).slice(-2);
    if (type == "zh") {
      return currentTime.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;
    } else if (type == "en") {
      let _month = currentTime.getMonth();
      let monthEn = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return day + " " + monthEn[_month] + " " + currentTime.getFullYear() + " at " + hour + " : " + minutes;
    } else if (type == "en-s") {
      return day + "-" + month + "-" + currentTime.getFullYear() + " " + hour + ":" + minutes + ":" + seconds;
    } else if (type === "en-ss") {
      let _month = currentTime.getMonth();
      let monthEn = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return monthEn[_month] + " " + day;
    }
    // 2022-06-30 21:20:10.000
    // 2022-07-01 18:20:35.000
  },
  base64ToBlob(code) {
    let parts = code.split(";base64,");
    let contentType = parts[0].split(":")[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  },
  sizeFormat(size) {
    return (size / 1024 / 1024).toFixed(2);
  },
  typeIco(type) {
    let s = type.split("/")[1];
    if (s == "mp4") {
      return "icon-video";
    }
  },
  //深度拷贝
  deepCopy(data, hash = new WeakMap()) {
    if (typeof data !== "object" || data === null) {
      throw new TypeError("传入参数不是对象");
    }
    // 判断传入的待拷贝对象的引用是否存在于hash中
    if (hash.has(data)) {
      return hash.get(data);
    }
    let newData = {};
    const dataKeys = Object.keys(data);
    dataKeys.forEach((value) => {
      const currentDataValue = data[value];
      // 基本数据类型的值和函数直接赋值拷贝
      if (typeof currentDataValue !== "object" || currentDataValue === null) {
        newData[value] = currentDataValue;
      } else if (Array.isArray(currentDataValue)) {
        // 实现数组的深拷贝
        newData[value] = [...currentDataValue];
      } else if (currentDataValue instanceof Set) {
        // 实现set数据的深拷贝
        newData[value] = new Set([...currentDataValue]);
      } else if (currentDataValue instanceof Map) {
        // 实现map数据的深拷贝
        newData[value] = new Map([...currentDataValue]);
      } else {
        // 将这个待拷贝对象的引用存于hash中
        hash.set(data, data);
        // 普通对象则递归赋值
        newData[value] = deepCopy(currentDataValue, hash);
      }
    });
    return newData;
  },
  unique(arr, uniId){
    const res = new Map();
    return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
  },
  getLevelPrice(l){
    if(l == 0){
      return 100;
    }
    else{
      return 2000*Math.pow(2,l-1)
    }
  },
  getUserShortName(item){
    let str = "";
    if(item.firstname){
      str += item.firstname.substring(0,1)
    }
    if(item.lastname){
      str += item.lastname.substring(0,1)
    }
    console.log(str)
    return str
  },
  getLevelName(l){
    if(loadConfig.level[l]){
      return loadConfig.level[l]['name']
    }else{
      return loadConfig.level[loadConfig.level.length - 1]['name']
    }
  },
   /**
   * 格式化时间部分，不含日期
   * @param {*} time 秒数
   * @returns
   */
   formatHour(time) {
    let hour = ("0" + Math.floor(time / 3600)).slice(-2);
    let minutes = ("0" + Math.floor((time % 3600) / 60)).slice(-2);
    let seconds = ("0" + ((time % 3600) % 60)).slice(-2);
    return hour + ":" + minutes + ":" + seconds;
  },
};
