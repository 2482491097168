import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import noZoom from "./assets/js/noZoom";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true
    };
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {
        if (this.userData.id) this.isLoading = true;
        if (this.userData.in_app && !["/home", "/tasks", "/learderboard", "/frends", "/airdrop", "/sign"].includes(this.route)) {
          console.log("this.userData.in_app", this.userData.in_app);
          this.$router.push("/home");
        }
      },
      immediate: true,
      deep: true
    },
    "$route.path": {
      handler(newPath, oldPath) {
        console.log("$route.path", newPath, oldPath);
        if (newPath === "/" || newPath === "/home") {
          this.WebApp.BackButton.isVisible && this.WebApp.BackButton.hide();
          this.WebApp.isClosingConfirmationEnabled = true;
          return;
        }
        this.WebApp.BackButton.show();
      },
      deep: true
    }
  },
  methods: {
    ...walletVuex.mapActions(["getSetting", "loadTaskCount"]),
    ...userVuex.mapActions(["login", "getUserData"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    initStatistics() {
      document.title = this.setting.coin;
      if (this.setting.statistics.length > 0) {
        this.setting.statistics.map(item => {
          if (item.type == "plausible" && item.value) {
            const pScript = document.createElement("script");
            pScript.src = `https://plausible.io/js/script.js`;
            pScript.defer = true;
            pScript.setAttribute("data-domain", item.value);
            document.head.appendChild(pScript);
          }
          if (item.type == "google" && item.value) {
            const gaScript = document.createElement("script");
            gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${item.value}`;
            gaScript.async = true;
            gaScript.onload = () => {
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag("js", new Date());
              gtag("config", item.value);
            };
            document.head.appendChild(gaScript);
          }
        });
      }
    }
  },
  async mounted() {
    console.dir(document.body);
    document.body.style.background = this.themeMainColor(this.theme).main;
    await this.getSetting();
    await this.login([this.WebApp.initData, this.WebApp.initDataUnsafe.start_param || ""]);
    //await this.login(["query_id=AAFXxo52AAAAAFfGjnYe2k-n&user=%7B%22id%22%3A1989068375%2C%22first_name%22%3A%22Yong%22%2C%22last_name%22%3A%22Goo%22%2C%22username%22%3A%22Yanggooo%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1720930660&hash=1686acc9fb9f543f7aec82577b75338bfd7dcda1daaf955e6ac0804f455fedf9", this.WebApp.initDataUnsafe.start_param || ""]);
    this.initStatistics();
    this.WebApp.BackButton.onClick(() => {
      this.$router.go(-1);
    });
    this.WebApp.isClosingConfirmationEnabled = true;
  }
};