import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "index",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  methods: {
    onSkip() {
      this.$router.push("/check");
    }
  }
};