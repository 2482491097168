import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "check",
  computed: {
    ...walletVuex.mapState(["theme"])
  },
  data() {
    return {
      progressList: [{
        show: false,
        progress: 0,
        isOk: false
      }, {
        show: false,
        progress: 0,
        isOk: false
      }, {
        show: false,
        progress: 0,
        isOk: false
      }, {
        show: false,
        progress: 0,
        isOk: false
      }],
      isContinue: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.clearIntervalFn();
    next();
  },
  methods: {
    clearIntervalFn() {
      console.log("我将准备清除所有定时器");
      for (let i = 1; i < 30000; i++) {
        clearInterval(i);
      }
    },
    onContinue() {
      this.$router.push("/ris");
    },
    animationFn() {
      this.isContinue = false;
      const fn = (index, callback) => {
        this.progressList[index].progress += 3;
        if (this.progressList[index].progress >= 400) {
          this.progressList[index].isOk = true;
          if (index === 3) {
            this.clearIntervalFn();
          }
        }
        if (this.progressList[index].progress >= 200) {
          typeof callback == "function" && callback();
        }
      };
      let timer = setTimeout(() => {
        clearTimeout(timer);
        this.progressList[0].show = true;
      }, 10);
      let progressTimer = setInterval(() => {
        fn(0, () => {
          this.progressList[1].show = true;
          fn(1, () => {
            this.progressList[2].show = true;
            fn(2, () => {
              this.progressList[2].show = true;
              fn(3, () => {
                this.progressList[3].show = true;
                this.isContinue = true;
              });
            });
          });
        });
      }, 10);
    }
  },
  mounted() {
    this.animationFn();
  }
};