import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import VueClipboard from "vue-clipboard2";
import Vue from "vue";
Vue.use(VueClipboard);
export default {
  name: "home",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"])
  },
  methods: {
    onShare() {
      this.WebApp.openTelegramLink(`https://t.me/share/url?text=Who%20let%20the%20${this.setting.coin}%20out%20&url=https://t.me/${this.setting.botname}/app?startapp=ref_${this.userData.ref_code}`);
    },
    onCopy() {
      const that = this;
      this.$copyText(`https://t.me/${this.setting.botname}/app?startapp=ref_${this.userData.ref_code}`).then(function (e) {
        that.$toasted.clear();
        that.$toasted.success(that.$lang("Copy Success!"));
      }, function (e) {});
    }
  }
};