var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "90%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Apple Tips")) + ":")]), _vm._v(" " + _vm._s(_vm.$lang("Add a space before sharing in Telegram groups for smoother sharing")) + " ")]), _c('div', {
    staticClass: "img"
  }, [_c('div', {
    staticClass: "re"
  }, [_c('img')])]), _c('div', {
    staticClass: "form-radios",
    on: {
      "click": _vm.onShowAgain
    }
  }, [_c('span', [_vm.isShowAgain ? _c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  }) : _vm._e()], 1), _vm._v(" " + _vm._s(_vm.$lang("Don't show again")) + " ")]), _c('div', {
    staticClass: "btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onUnderstand
    }
  }, [_vm._v(_vm._s(_vm.$lang("I Understand")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };