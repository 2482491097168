var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index",
    class: _vm.theme,
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/bg1.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "index-main"
  }, [_c('div', {
    staticClass: "index-car"
  }, [_c('div', {
    staticClass: "index-car-main",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-logo.png`)})`
    }
  })]), _c('div', {
    staticClass: "index-info"
  }, [_vm.theme === 'car' ? _c('p', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/car_z.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$lang("Hey!")) + " ")]) : _vm._e(), _c('p', {
    staticClass: "xs"
  }, [_vm._v(" " + _vm._s(_vm.$lang("You've been in Telegram for a while,"))), _c('br'), _vm._v(" " + _vm._s(_vm.$lang("it's time to get rewarded!")) + " ")])]), _c('div', {
    staticClass: "index-btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).btnMain,
      color: _vm.themeMainColor(_vm.theme).btnMainText
    },
    on: {
      "click": _vm.onSkip
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Wow, Let'go")) + " ")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };