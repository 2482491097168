import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    token:'',
    userData: {}
  },
  mutations: {
    setToken: (state, payload)=> {
      state.token = payload
    },
    setUserData: (state, payload) => {
      for (const key in payload) {
        Vue.prototype.$set(state.userData, key, payload[key]);
      }
    }
  },
  actions: {
    login: async ({ state, commit, dispatch }, [data, start_param, callback]) => {
      let inviteCode = "";
      let groupId = "";
      if (start_param && start_param.indexOf("ref_") !== -1) {
        let tstart_param =  start_param.split("-")
        console.log("tstart_param",tstart_param)
        tstart_param.map(item=>{
          if(item.indexOf("ref_") !== -1){
            inviteCode = item.replace("ref_", "");
          }
        })
      }
      const rs = await Vue.prototype.$http.post("/auth/login", { initData: data, inviteCode, groupId });
      vailcode(rs, async () => {
        console.log(rs.data);
        commit("setToken", rs.data.token);
        commit("setUserData", rs.data.userInfo);
        commit("wallet/setTaskCount",rs.data.task_count|| 0, {root: true})
        typeof callback == "function" && callback(rs.data);
      });
    },
    join:async ({ state, commit, dispatch }, [target, address, num, callback]) => {
      if(num > 5){
        return;
      }
      const rs = await Vue.prototype.$http.post("/user/join", { target, address });
      if(rs.code == 0)
       {
        commit("setUserData", rs.data);
        typeof callback == "function" && callback(rs.data);
      }else{
        setTimeout(async ()=>{
          await dispatch("join", [target, address,num + 1, callback])
        },2000)
      };
    }
  },
  getters: {},
};
