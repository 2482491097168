import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const rankingVuex = createNamespacedHelpers("ranking");
export default {
  name: "leaderboard",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...rankingVuex.mapState(["personalList", "personalTotal"])
  },
  mounted() {
    if (!this.personalList || this.personalList.length == 0) {
      this.getPersonalList();
    }
  },
  methods: {
    ...rankingVuex.mapActions(["getPersonalList"])
  }
};