import Vue from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import store from "./store";
import "./assets/css/reset.scss";
import "./assets/icons"; //svg图标
import "./assets/js/rem";
import "./assets/fonts/font.css";
import Axios from "./http/index";
import router from "./router";
import formatter from "./utils/formatter";
import WebApp from "@twa-dev/sdk";
import directive from "./directive";
import Toasted from "vue-toasted";
// import VConsole from 'vconsole';
// const vConsole = new VConsole();
const options = {
  duration: 3000,
  position: "bottom-right",
  fullWidth: false,
};
Vue.use(Toasted, options);

Vue.use(directive);

import myLoading from "./components/myLoading";
Vue.use(myLoading);

for (let func in formatter) {
  Vue.filter(func, formatter[func]);
}

Vue.prototype.formatter = formatter;

Vue.config.productionTip = false;

Vue.prototype.registerBodyClick = (isRegister, callback) => {
  isRegister ? document.body.addEventListener("click", callback) : document.body.removeEventListener("click", callback);
};

const themeMainColor = (theme) => {
  let _theme = {};
  switch (theme) {
    case "car":
      _theme = {
        main: "#113183",
        fontFamily: 'pFont',
        btnMain: "#216fe4",
        btnMainText: "#fff",
        btnHome: "#FFF",
        btnHomeText: "#101010",
        ok: "#ffc411",
        ago: "#fff",
        homeText: "#fff",
        rewardItemBg: "#365dbc",
        listBg: "rgba(9, 39, 112, 0.7)",
        iconBg: "rgba(16, 54, 148, 0.5)",
        iconColor: "#fff",
        mNav: "linear-gradient(to top, #355cba, #264aa0)",
        ownItemBg: "#365dbc",
        leaderPageItemColor:"#365dbc4a",
        leaderPageNumColor: "rgba(255, 255, 255, 0.5)",
        taskItemBg: "#365dbc",
        exraTaskItemBg: "rgba(54, 93, 188, 0.6)",
        taskItemIconBg: "#1036947a",
        taskItemIconColor: "#fff"
      };
      break;
    case "human":
      _theme = {
        main: "#000",
        fontFamily: 'aFont',
        btnMain: "#a0fe07",
        btnMainText: "#000000",
        btnHome: "#a0fe07",
        btnHomeText: "#000000",
        ok: "#a0fe07",
        ago: "#a0fe07",
        homeText: "#a0fe07",
        rewardItemBg: "rgba(30, 30, 32)",
        listBg: "rgba(30, 30, 32)",
        iconBg: "#a0fe07",
        iconColor: "#000",
        mNav: "#000",
        ownItemBg: "#1e1e20",
        leaderPageNumColor: "#a0fe07",
        leaderPageItemColor:"#1e1e20",
        taskItemBg: "#1e1e20",
        exraTaskItemBg: "#1e1e20",
        taskItemIconBg: "#a0fe07",
        taskItemIconColor: "#000000"
      };
      break;
  }
  return _theme
};

Vue.prototype.themeMainColor = themeMainColor

Axios.install(Vue);

WebApp.ready();
WebApp.expand();

Vue.prototype.WebApp = WebApp;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
