var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "sign",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "sign-main"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/sign.png`)
    }
  })]), _c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "title_subtitle"
  }, [_vm._v(_vm._s(_vm.$lang("Daily Login")))])]), _c('div', {
    staticClass: "items"
  }, _vm._l(_vm.steps, function (item, index) {
    return _c('div', {
      staticClass: "item",
      class: item.today ? 'item__today' : '',
      on: {
        "click": function ($event) {
          return _vm.sign_in(item);
        }
      }
    }, [_c('div', {
      staticClass: "item__box",
      class: item.completed ? 'has_sign' : ''
    }, [_c('div', {
      staticClass: "item__header",
      class: item.name == 'BIG DAY' ? 'item__bigday' : ''
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "item__content"
    }, [item.completed ? _c('img', {
      staticClass: "sign_img",
      attrs: {
        "src": require(`@images/${_vm.theme}/finish.png`)
      }
    }) : _vm._e(), _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/icon-coin.png`)
      }
    }), _c('div', {
      staticClass: "item__coin"
    }, [_vm._v(" " + _vm._s(_vm._f("nFormatter")(item.coins * 3)) + " ")])])]), item.today && !item.completed ? _c('div', {
      staticClass: "item__title"
    }, [_vm._v(" Claim ")]) : _vm._e(), _vm.steps[index - 1] && _vm.steps[index - 1].today && _vm.steps[index - 1].completed ? _c('div', [_vm._v(" " + _vm._s(_vm.lastTimeStr) + " ")]) : _vm._e()]);
  }), 0)]), _c('pop-sign', {
    attrs: {
      "show": _vm.popSignShow,
      "taskInfo": _vm.todayItem
    },
    on: {
      "update:show": function ($event) {
        _vm.popSignShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };