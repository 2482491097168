import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "popBindWallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "tonConnect"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {};
  },
  watch: {
    "$parent.popBindWalletShow": {
      handler(bool) {
        if (bool) {
          console.log("this.tonWallett", this.tonWallet);
        }
      },
      deep: true,
      immediate: true
    },
    tonConnect: {
      async handler(newVal, oldVal) {
        if (newVal && !this.userData[`joined_wallet`]) {
          await this.join(['wallet', this.tonWallet.uiAddress, 1]);
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...userVuex.mapActions(["join"]),
    onDisconnect() {
      this.tonWallet.disconnect();
    },
    onTonConnect() {
      this.$parent.popBindWalletShow = false;
      this.$nextTick(() => {
        this.tonWallet.openOrCloseTonModal(true);
      });
    }
  }
};