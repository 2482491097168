import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "myHead",
  props: {
    src: {
      default: null,
      type: String
    },
    bgColor: {
      type: String,
      default: "#fff"
    },
    name: null,
    size: {
      type: [String, Number],
      default: "40"
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  }
};