var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "leaderboard",
    class: _vm.theme,
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    }
  }, [_c('div', {
    staticClass: "leaderboard-main"
  }, [_vm.theme === 'car' ? _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Telegram Wall of Fame")))]) : _vm.theme === 'human' ? _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Wall of Fame")))]) : _vm._e(), _c('div', {
    staticClass: "own"
  }, [_c('div', {
    staticClass: "own-item",
    style: {
      background: _vm.themeMainColor(_vm.theme).ownItemBg
    }
  }, [_c('m-head', {
    attrs: {
      "name": _vm.userData,
      "bgColor": _vm.userData.head_color
    }
  }), _c('div', {
    staticClass: "reward-item__name"
  }, [_c('span', [_vm._v(_vm._s(_vm.userData.firstname) + " " + _vm._s(_vm.userData.lastname))]), _c('span', {
    staticClass: "n",
    style: {
      color: _vm.themeMainColor(_vm.theme).leaderPageNumColor
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)) + " " + _vm._s(_vm.setting.coin))])]), _c('div', {
    staticClass: "reward-item__num"
  }, [_vm._v("#" + _vm._s(_vm.userData.ranking))])], 1)]), _c('div', {
    staticClass: "list"
  }, [_vm.theme == 'car' ? _c('div', {
    staticClass: "list-title"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.personalTotal, 2)) + " " + _vm._s(_vm.$lang("holders")))]) : _vm._e(), _vm.theme == 'human' ? _c('div', {
    staticClass: "list-title"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.personalTotal, 2)) + " " + _vm._s(_vm.$lang("humans")))]) : _vm._e(), _vm.personalList && _vm.personalList.length > 0 ? _c('div', {
    staticClass: "list-main"
  }, _vm._l(_vm.personalList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      style: {
        background: _vm.themeMainColor(_vm.theme).leaderPageItemColor
      }
    }, [_c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    }), _c('div', {
      staticClass: "reward-item__name"
    }, [_c('span', [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('span', {
      staticClass: "n",
      style: {
        color: _vm.themeMainColor(_vm.theme).leaderPageNumColor
      }
    }, [_vm._v(_vm._s(_vm._f("toThousands")(item.coin)) + " " + _vm._s(_vm.setting.coin))])]), _c('div', {
      staticClass: "rank-img"
    }, [index < 3 ? [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/rank-${index + 1}.png`),
        "alt": ""
      }
    })] : [_vm._v("#" + _vm._s(index + 1))]], 2)], 1);
  }), 0) : _vm._e()])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };